import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const InfoBlock = makeShortcode("InfoBlock");
const Box = makeShortcode("Box");
const Flex = makeShortcode("Flex");
const Button = makeShortcode("Button");
const Image = makeShortcode("Image");
const Aligner = makeShortcode("Aligner");
const Column = makeShortcode("Column");
const Process = makeShortcode("Process");
const Fellows = makeShortcode("Fellows");
const Projects = makeShortcode("Projects");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <InfoBlock mdxType="InfoBlock">
      <Box mdxType="Box">
        <h1 {...{
          "id": "span-stylefont-sizelargerkernelspan-is-a-peer-learning-institution",
          "style": {
            "position": "relative"
          }
        }}><span style={{
            "fontSize": "larger"
          }}>{`Kernel`}</span>{` is a peer-learning institution`}<a parentName="h1" {...{
            "href": "#span-stylefont-sizelargerkernelspan-is-a-peer-learning-institution",
            "aria-label": "span stylefont sizelargerkernelspan is a peer learning institution permalink",
            "className": "anchor-link after"
          }}><span parentName="a">{`¶`}</span></a></h1>
        <p>{`A Kernel Block welcomes you into Kernel with ~200 unique, talented, and diverse peers.`}</p>
        <p>{`In a block, fellows hone a craft, further projects, and explore fundamental ideas related to the web.`}</p>
        <p>{`Each block is a unique experience - co-directed by fellows, dedicated to building a better web, together.`}</p>
        <Flex sx={{
          alignItems: 'center'
        }} mdxType="Flex">
  <Button to='/start/' secondary inline mdxType="Button">
    Curious?
  </Button>
  <Button to='https://apply.kernel.community' inline mdxType="Button">
    Apply
  </Button>
        </Flex>
        <p><strong parentName="p">{`Kernel Block 9 runs July-September 2024.`}</strong></p>
      </Box>
      <Image src="/images/kernel_lp.png" title="Welcome to Kernel" mdxType="Image" />
    </InfoBlock>
    <InfoBlock mdxType="InfoBlock">
      <Image src="/images/kernel_learn.png" title="Learn" mdxType="Image" />
      <Box mdxType="Box">
        <h1 {...{
          "id": "learn--craft",
          "style": {
            "position": "relative"
          }
        }}>{`Learn & Craft`}<a parentName="h1" {...{
            "href": "#learn--craft",
            "aria-label": "learn  craft permalink",
            "className": "anchor-link after"
          }}><span parentName="a">{`¶`}</span></a></h1>
        <p>{`Our web is our shared environment. The Kernel book displays how decentralized, peer-to-peer, open-source, and onchain technologies help us relate and serves as a source of dialogue for Kernel Fellows during a block.`}</p>
        <Button to="/start/" mdxType="Button">Explore the Kernel Book</Button>
      </Box>
    </InfoBlock>
    <Aligner center mdxType="Aligner">
      <h2 {...{
        "id": "kernel-principles",
        "style": {
          "position": "relative"
        }
      }}>{`Kernel Principles`}<a parentName="h2" {...{
          "href": "#kernel-principles",
          "aria-label": "kernel principles permalink",
          "className": "anchor-link after"
        }}><span parentName="a">{`¶`}</span></a></h2>
    </Aligner>
    <Aligner center mdxType="Aligner">
      <Box sx={{
        width: '80%',
        textAlign: 'center'
      }} mdxType="Box">
        <p>{`Kernel is about balance. Each week we explore two principles through dialogue and our chosen craft. The priority is relating well.`}</p>
      </Box>
    </Aligner>
    <Box sx={{
      width: '80%',
      margin: '0 auto'
    }} mdxType="Box">
      <Column mdxType="Column">
        <Box mdxType="Box">
          <h3 {...{
            "id": "️-web-3-principles",
            "style": {
              "position": "relative"
            }
          }}>{`⌛️ Web 3 Principles`}<a parentName="h3" {...{
              "href": "#%EF%B8%8F-web-3-principles",
              "aria-label": "️ web 3 principles permalink",
              "className": "anchor-link after"
            }}><span parentName="a">{`¶`}</span></a></h3>
          <Process mdxType="Process">
            <p>{`Trust is not only transactional.`}</p>
            <p>{`Shared truths create value.`}</p>
            <p>{`Learn the limits. Then you're free.`}</p>
            <p>{`Money means speech.`}</p>
            <p>{`Incentives inform everything.`}</p>
            <p>{`Liberate radical institutions.`}</p>
            <p>{`Resist censorship economically.`}</p>
            <p>{`Scale ability.`}</p>
          </Process>
        </Box>
        <Box mdxType="Box">
          <h3 {...{
            "id": "-personal-principles",
            "style": {
              "position": "relative"
            }
          }}>{`🌈 Personal Principles`}<a parentName="h3" {...{
              "href": "#-personal-principles",
              "aria-label": " personal principles permalink",
              "className": "anchor-link after"
            }}><span parentName="a">{`¶`}</span></a></h3>
          <Process mdxType="Process">
            <p>{`Play with pattern.`}</p>
            <p>{`Develop the means to mean.`}</p>
            <p>{`Ask better questions.`}</p>
            <p>{`Consider your intention first.`}</p>
            <p>{`Listen to and tell better stories.`}</p>
            <p>{`Together, individuals govern well.`}</p>
            <p>{`Learn how to love learning.`}</p>
            <p>{`Giving is sacred.`}</p>
          </Process>
        </Box>
      </Column>
    </Box>
    <InfoBlock sx={{
      width: '80%'
    }} mdxType="InfoBlock">
      <Flex sx={{
        flexDirection: "column"
      }} mdxType="Flex">
        <Aligner center sx={{
          marginTop: "-4rem"
        }} mdxType="Aligner">
          <h2 {...{
            "id": "meet-the-kernel-fellows",
            "style": {
              "position": "relative"
            }
          }}>{`Meet The Kernel Fellows`}<a parentName="h2" {...{
              "href": "#meet-the-kernel-fellows",
              "aria-label": "meet the kernel fellows permalink",
              "className": "anchor-link after"
            }}><span parentName="a">{`¶`}</span></a></h2>
        </Aligner>
      </Flex>
    </InfoBlock>
    <Aligner center mdxType="Aligner">
      <Fellows mdxType="Fellows" />
    </Aligner>
    <InfoBlock sx={{
      width: '80%'
    }} mdxType="InfoBlock">
      <Flex sx={{
        flexDirection: "column"
      }} mdxType="Flex">
        <Aligner center sx={{
          marginTop: "-4rem"
        }} mdxType="Aligner">
          <h2 {...{
            "id": "projects--teams",
            "style": {
              "position": "relative"
            }
          }}>{`Projects & Teams`}<a parentName="h2" {...{
              "href": "#projects--teams",
              "aria-label": "projects  teams permalink",
              "className": "anchor-link after"
            }}><span parentName="a">{`¶`}</span></a></h2>
        </Aligner>
      </Flex>
    </InfoBlock>
    <Aligner center mdxType="Aligner">
      <Projects mdxType="Projects" />
    </Aligner>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      